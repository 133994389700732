import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 8 18">
    <path
      fill={color}
      d="M6.5,0.6c0.2,0,0.4,0.1,0.6,0.2c0.5,0.3,0.6,1,0.3,1.5L2.7,9l4.7,6.8c0.3,0.5,0.2,1.1-0.3,1.5
	c-0.5,0.3-1.1,0.2-1.5-0.3L0.6,9.6c-0.2-0.4-0.2-0.8,0-1.2l5.1-7.4C5.9,0.8,6.2,0.6,6.5,0.6z"
    />
  </svg>
);
